.hero-banner {
  position: relative;
  height: 50vh;
  background-image: url('../assets/clouds.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-text {
  position: absolute;
  top: 40%;
  left: 50%;
  text-align: center;
  font-size: 3.5rem;
  color: var(--ivory);
  text-shadow: 2px 2px 6px var(--brown);
  transform: translate(-50%, -50%);
}

.leafAndFeatherBanner {
  margin-top: -200px;
  width: 100vw;
  z-index: -100;
  max-width: 700px;
}

.subHeading {
  text-align: center;
}

.subHeading p {
  line-height: 1.3;
}

.landingPageLinks {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 4%;
}

@media only screen and (max-width: 636px) {
  .leafAndFeatherBanner {
    margin-top: -60px;
  }

  .landingPageLinks {
    justify-content: center;
  }
}
