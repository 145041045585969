:root {
  --gold: #b27409;
  --green: #3e524c;
  --peach: #f5ede5;
  --ivory: #efede9;
  --brown: #7b6759;
  --taupe: #737373;
  --blue: #ecf2fe;
}

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  /*overflow-x: hidden;
  overflow-y: hidden;*/
  margin: 0px;
  padding: 0px;
  background-color: var(--ivory);
}

h1 {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 3rem;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  color: var(--brown);
}

h2 {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 0.3rem;
  color: var(--brown);
}

.subtitle {
  color: var(--brown);
  text-transform: uppercase;
}

.details-main {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  text-align: center;
}

.details-p {
  font-family: 'Playfair Display', serif;
  font-size: 1.2rem;
  line-height: 1.7;
  color: var(--taupe);
}

.greeting {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 20px 0 20px;
  font-family: 'Josefin Slab', serif;
  line-height: 1.7;
  color: var(--green);
}

@media screen and (prefers-reduced-motion: reduce),
(update: slow) {
  * {
    animation-duration: 0.001ms !important;
    transition-duration: 0.001ms !important;
  }
}

@media only screen and (min-device-width : 20em) and (max-device-width : 50em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {

  html,
  body {
    overflow-x: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .details-main {
    margin-top: 5rem;
  }

  .details-p {
    margin: 3rem;
  }
}
