.upper-leaf {
  position: absolute;
  width: 30%;
  z-index: -100;
}

.lower-leaf {
  position: absolute;
  right: 0;
  width: 30%;
  z-index: -100;
}

#top {
  margin-top: 20px;
}

.details-section h2 {
  margin-bottom: 1rem;
}

.details-section a {
  text-decoration: none;
  color: var(--green);
}

.details-section a:hover {
  color: var(--gold);
  cursor: pointer;
}

@media only screen and (max-width: 636px) {
  .upper-leaf {
    margin-top: 50%;
    width: 30%;
  }

  .lower-leaf {
    margin-top: 50%;
    width: 30%;
  }

  #top {
    margin-top: 0px;
  }

  .details-section p {
    margin-top: 1rem;
  }
}
