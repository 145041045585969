.accommodations-section {
  margin-top: 20px;
}

.accommodations-section p {
  margin: 0.5rem;
}

.ext-link-section {
  margin: 1rem;
}

.ext-link-section a {
  text-decoration: none;
  color: var(--green);
}

.ext-link-section p {
  margin-bottom: 2rem;
}

.ext-link-section a:hover {
  color: var(--gold);
  cursor: pointer;
}

.left-leaf-banner {
  position: absolute;
  margin-top: 20%;
  left: 0;
  width: 40%;
  z-index: -100;
}

.right-leaf-banner {
  position: absolute;
  margin-top: 20%;
  right: 0;
  width: 40%;
  z-index: -100;
}

@media only screen and (max-width: 636px) {
  .accommodations-section {
    margin-top: 0px;
  }

  .ext-link-section {
    margin-top: 2.5rem;
  }

  .left-leaf-banner {
    margin-top: 70%;
  }

  .right-leaf-banner {
    margin-top: 70%;
  }
}
