.left-leaf-banner-gift {
  position: absolute;
  margin-top: 25%;
  left: 0;
  width: 40%;
  z-index: -100;
}

.right-leaf-banner-gift {
  position: absolute;
  margin-top: 25%;
  right: 0;
  width: 40%;
  z-index: -100;
}


@media only screen and (max-width: 636px) {
  .accommodations-section {
    margin-top: 0px;
  }

  .ext-link-section {
    margin-top: 2.5rem;
  }

  .left-leaf-banner-gift {
    margin-top: 120%;
  }

  .right-leaf-banner-gift {
    margin-top: 120%;
  }
}
