nav {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  z-index: 100;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  color: black;
  letter-spacing: 3px;
  background-color: white;
}

.link {
  padding: 0.5rem;
  text-decoration: none;
  color: var(--gold);
  transition: 0.45s ease-in-out;
  cursor: pointer;
}

.link:hover {
  background-color: var(--blue);
  cursor: pointer;
}

.burgerMenu {
  position: fixed;
}

@media(max-width: 1050px) {
  .link {
    display: none;
  }
}

@media(min-width: 1050px) {
  .burgerMenu {
    display: none;
  }
}
