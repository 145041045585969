#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked+.menu__btn>span {
  transform: rotate(45deg);
}

#menu__toggle:checked+.menu__btn>span::before {
  top: 0;
  transform: rotate(0deg);
}

#menu__toggle:checked+.menu__btn>span::after {
  top: 0;
  transform: rotate(90deg);
}

#menu__toggle:checked~.menu__box {
  right: 0 !important;
}

.menu__btn {
  position: fixed;
  top: 35px;
  right: 20px;
  width: 26px;
  height: 26px;
  z-index: 100;
  cursor: pointer;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--gold);
  opacity: 0.8;
  transition-duration: .25s;
}

.menu__btn>span::before {
  content: '';
  top: -8px;
}

.menu__btn>span::after {
  content: '';
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  transition-duration: .50s;
}

.menu__item {
  display: block;
  padding: 12px 24px;
  text-decoration: none;
  color: var(--gold);
  transition-duration: .50s;
}

.menu__item:hover {
  background-color: var(--blue);
}
