.photo-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10% 5%;
}

.photo-image {
  margin: 5px;
  max-height: 200px;
  border: 3px solid var(--ivory);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.3);
  -webkit-transition: .7s ease-out;
  transition: .7s ease-out;
}

.photo-image:hover {
  -webkit-transform: scale(2.5);
  transform: scale(2.5);
  -webkit-transform: .5s ease-in;
  transition: .5s ease-in;
}

@media only screen and (max-width: 636px) {
  .photo-image {
    margin: 10px;
  }
}
