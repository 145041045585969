.footer-span {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 110%;
  margin-top: 5%;
}

.footer-logo {
  height: 30px;
}

.footer-span p {
  margin-left: 5px;
  font-size: 10px;
  color: var(--gold);
}
